
.FeedbackDetail {
	margin: 1.5rem 0;;


}

	.FeedbackDetail-title {
		margin: 0;
	}

	.FeedbackDetail-type {
		margin: 0;
	}

.FeedbackEditor {
	background-color: #f5f5f5;
	padding: .5rem;
	border-radius: .25rem;
}
