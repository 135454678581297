.TemplatesLayout {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 4rem;
	margin-top: 1rem;
}

.Templates, .CategoryTemplates {
	background-color: #f9f9f9;
	margin: 0 auto;
	padding: 1rem;
	width: 100%;

}
.CategoryTemplates-Form * {
	display: block;
}
.CategoryTemplates-Form .button-alternate {
	width: 100%;
	margin-top: 1rem;
}
.CategoryTemplates-Form--category {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 15px;
}

.CategoryTemplates-Form--templates {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 15px;
}
.CategoryTemplates-List {
	margin-top: 1rem;
}

.CategoryTemplates-Item {
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	background-color: white;
	padding: 10px;
	margin-bottom: 10px;



}

.CategoryTemplates-Item--emoji {
	width: 24px;
	height: 24px;
	font-size: 16px;
	margin-right: 10px;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
}

.CategoryTemplates-Item--name {
	flex: 1 1 auto;
}

.CategoryTemplates-Item--category {
	color: #696773;
	font-size: 13px;
}
