.EditorTextImage {
	position: relative;
	width: 100%;



}
.EditorTextImage button {
	position: absolute;
	top: 0;
	right: 0;
}

.EditorTextImage img {
	display: block;
	width: 100%;
}
