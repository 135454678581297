
/*

// Breakpoints
$bp-small: 48em; // 768px
$bp-medium: 64em; // 1024px
$bp-large: 85.375em; // 1366px
$bp-xlarge: 120em; // 1920px
$bp-xxlarge: 160em; // 2560px

$mq-small: "(min-width: #{$bp-small})";
$mq-medium: "(min-width: #{$bp-medium})";
$mq-large: "(min-width: #{$bp-large})";
$mq-xlarge: "(min-width: #{$bp-xlarge})";
$mq-xxlarge: "(min-width: #{$bp-xxlarge})";
$mq-retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";


.SearchTable {
  align-content: center;
  align-items: center;
  display: grid;
  grid-gap: 10px 0;
  justify-content: center;
	margin-top: 1rem;

  @media #{$mq-medium} {
    display: flex;
    justify-content: flex-end;
  }

  & > * {
    width: 95vw;
    @media #{$mq-medium} {
      width: auto;
    }
  }

  & > div {
    height: 40px !important;
    order: 1;
    
    @media #{$mq-medium} {
      margin-right: 10px;
      order: 0;
    }
  }

  .mdc-text-field .mdc-floating-label {
    top: 13px !important;
  }

  i {
    bottom: 7px !important;
  }
}
*/


table {
  width: 100%;
  display: table;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border-spacing: 0;
  border-collapse: collapse;
  min-width: 700px;
}

tr {
  color: inherit;
  height: 48px;
  display: table-row;
  outline: none;
  vertical-align: middle;
}


thead {
  display: table-header-group;

}
thead tr {
	height: 56px;
}
th {
  display: table-cell;
  padding: 4px 56px 4px 24px;
  text-align: left;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  vertical-align: inherit;
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.75rem;
  font-weight: 500;
}


tbody {
  display: table-row-group;
}

td {
  display: table-cell;
  padding: 4px 56px 4px 24px;
  text-align: left;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  vertical-align: inherit;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.8125rem;
  font-weight: 400;
}

.table--container {
  width: 100%;
  border-radius: 4px;
  margin-top: 24px;
  overflow-x: auto;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
}

